import stepMixin from '../../../mixins/stepMixin'
import customerMixin from '../../../mixins/customerMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import { getStepNumber } from '../../../utils/index'
import EventBus from '../../../utils/eventBus'
import moment from 'moment'
import TextList from '../../textList/TextList.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import Vin from '../vin/Vin.vue'
import LicensePlate from '../licensePlate/LicensePlate.vue'
import HandoverProtocol from '../handoverProtocol/HandoverProtocol.vue'
import Mileage from '../mileage/Mileage.vue'
import Damages from '../damages/Damages.vue'
import Signature from '../signature/Signature.vue'
import Pin from '../pin/Pin.vue'
import Verification from '../verification/Verification.vue'
import LogisticsPartner from '../logisticsPartner/LogisticsPartner.vue'
import RecordedMissingParts from '../recordedMissingParts/RecordedMissingParts.vue'
import { mapGetters } from 'vuex'


export default {
    name: 'Summary',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin, checkTaskMixin, customerMixin],

    components: {
        TextList,
        FormInput,
        FormCheck,
        Vin,
        LicensePlate,
        HandoverProtocol,
        Mileage,
        Damages,
        Signature,
        Pin,
        Verification,
        RecordedMissingParts,
        LogisticsPartner
    },

    data() {
        return {
            nowDay: '',
            nowHour: ''
        }
    },

    computed: {
        ...mapGetters({
            correctPin: 'pin/correctPin',
            loggedInUser: 'user/loggedInUser'
        }),

        loggedInUserCompany() {
            return (this.loggedInUser && this.loggedInUser.location) || ''
        },

        dateItems() {
            return [
                {
                    label: 'Ort',
                    value: this.loggedInUserCompany
                },
                {
                    label: 'Datum',
                    value: this.nowDay
                },
                {
                    label: 'Uhrzeit',
                    value: this.nowHour
                }
            ]
        },

        withoutSignature() {
            return (
                this.field?.withoutSignature
            )
        },

        withoutSignatureConfirmed() {
            return (
                this.field?.withoutSignatureConfirmed
            )
        },

        hasMissingItems() {
            return (this.form?.handoverProtocol?.['missingItems']?.length > 0) ||
                (this.field?.handoverProtocol?.['missingItemsText']?.length > 0)
        },
    },

    methods: {
        updateDates() {
            const now = moment()
            this.nowHour = now.format('HH:mm') + ' Uhr'
            this.nowDay = now.format('DD.MM.YYYY')
        },

        validate() {
            this.updateDates()
            let isValid = true

            if (this.isCustomerTask) {
                if (this.isEmpty(this.field.signature)) {
                    isValid = false
                }

                if (
                    !this.withoutSignature &&
                    (this.isEmpty(this.field.confirmed) ||
                        this.field.confirmed === false)
                ) {
                    isValid = false
                }

                if (
                    !this.withoutSignature &&
                    this.isCheckin &&
                    this.hasMissingItems &&
                    (this.isEmpty(this.field.confirmedMissingItemsCustomer) ||
                        this.field.confirmedMissingItemsCustomer === false
                    )
                ) {
                    isValid = false
                }

                if (
                    !this.withoutSignature &&
                    !this.correctPin &&
                    !this.isCheckin
                ) {
                    isValid = false
                }
            }

            if (this.isDrivingServiceTask) {
                if (this.isEmpty(this.field.signature)) {
                    isValid = false
                }

                if (
                    !this.withoutSignature &&
                    (this.isEmpty(this.field.confirmed) ||
                        this.field.confirmed === false)
                ) {
                    isValid = false
                }
            }

            if (this.withoutSignature && !this.withoutSignatureConfirmed) {
                isValid = false
            }

            if (this.isRecordMissingPartsTask) {
                isValid = this.field.confirmRetunredMissingItems === true
            }

            this.$emit('update:stepIsValid', isValid)
        },

        edit(step) {
            const stepNumber = getStepNumber(step, this.task)
            this.$router.push({ name: this.task, params: { step: stepNumber } })
        }
    },

    watch: {
        // Watch for finished summary by customer
        summaryFinishedByCustomer(val) {
            if (val) {
                EventBus.$emit('showDialog', {
                    dialog: {
                        name: 'vote',
                        title: this.taskTitle
                    },
                    task: this.task
                })
            }
        }
    }
}
