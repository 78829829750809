import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Signature',

    mixins: [stepMixin, checkTaskMixin],

    components: {
        FormCheck
    },

    props: {
        mayClear: Boolean,
        default: true
    },

    data() {
        return {
            signaturePad: null,
            withoutSignature: null
        }
    },

    mounted() {
        this.initSignaturePad()
    },

    computed: {
        ...mapGetters({
            correctPin: 'pin/correctPin'
        }),

        field() {
            return this.form.summary || {}
        },

        signatureMessage() {
            return this.isCustomerTask
                ? 'Lieber Kunde. Bitte prüfen Sie diese obrige Zusammenfassung auf Richtigkeit und bestätigen Sie diese nachfolgend durch Ihre Unterschrift und PIN.'
                : 'Lieber Fahrer. Bitte prüfen Sie diese obrige Zusammenfassung auf Richtigkeit und bestätigen Sie diese nachfolgend durch Ihre Unterschrift.'
        }
    },

    methods: {
        ...mapActions({
            setForm: 'form/setForm'
        }),

        initSignaturePad() {
            // eslint-disable-next-line
            this.signaturePad = new SignaturePad(
                document.getElementById('signature-pad'),
                {
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    penColor: 'rgb(0, 0, 0)',
                    onEnd: this.onEnd
                }
            )

            this.resizeCanvas()
        },

        resizeCanvas() {
            const canvas = document.getElementById('signature-pad')

            var ratio = Math.max(window.devicePixelRatio || 1, 1)
            canvas.width = canvas.offsetWidth * ratio
            canvas.height = canvas.offsetHeight * ratio
            canvas.getContext('2d').scale(ratio, ratio)
        },

        onEnd() {
            const data = this.signaturePad.toDataURL('image/png')
            this.setForm({
                key: 'signature',
                value: data,
                step: this.view
            })

            // Clear checkboxes
            // @TODO: move to props
            this.setForm({
                key: 'confirmed',
                value: false,
                step: this.view
            })
            this.setForm({
                key: 'withoutSignatureConfirmed',
                value: false,
                step: this.view
            })
        },

        clear() {
            this.signaturePad.clear()

            this.setForm({
                key: 'signature',
                value: null,
                step: this.view
            })
        }
    },

    watch: {
        correctPin(val) {
            if (val) {
                this.signaturePad.off()
            }
        },

        withoutSignature(value) {
            this.signaturePad.clear()

            this.setForm({
                key: 'signature',
                value: null,
                step: this.view
            })

            this.setForm({
                key: 'withoutSignature',
                value,
                step: this.view
            })
        }
    }
}
