import stepMixin from '../../../mixins/stepMixin'
import FormInput from '../../form/formInput/FormInput.vue'
import Signature from '../signature/Signature.vue'
import FormCheck from '../../form/formCheck/FormCheck.vue'

export default {
    name: 'LogisticsPartner',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin],

    components: {
        FormInput,
        FormCheck,
        Signature
    },

    data() {
        return {
            isDeregistrationHintAcknowledged: false,
            deregistrationHintAcknowledgedComment: ''
        }
    },

    methods: {
        validate() {
            let isValid = true

            if (this.isEmpty(this.field.lastName)) {
                isValid = false
            }

            if (this.isEmpty(this.field.firstName)) {
                isValid = false
            }

            if (this.isEmpty(this.field.company)) {
                isValid = false
            }

            if (this.isEmpty(this.field.signature)) {
                isValid = false
            }

            if (this.form.vehicle.isDeregistrationPending && this.task === 'CustomerCheckin' && (this.isEmpty(this.field.isDeregistrationHintAcknowledged) || !this.field.isDeregistrationHintAcknowledged)) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        }
    }
}
