import stepMixin from '../../../mixins/stepMixin'
import FormInput from '../../form/formInput/FormInput.vue'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import { missingHandoverItems } from '../../../constants/equipmentItems'

export default {
    name: 'RecordedMissingParts',

    mixins: [stepMixin],

    components: {
        FormCheck,
        FormInput
    },

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            panel: [0, 1, 2, 3],
            listToReturn: [],
            returnAllItemsAt: null
        }
    },

    created() {
        this.listToReturn = this.form.vehicle.orgMissingItems.map((part) => { return {
            ...part,
            returnedAt: (part.returnedAt ? this.toISODate(part.returnedAt) : null)
        } })
        this.validate();
    },

    computed: {
        hasMissingParts() {
            return this.checkinMissingParts.length > 0
        },

        hasAllReturned() {
            return this.checkinMissingParts.length == this.returnedMissingParts.length
        },

        checkinMissingParts() {
            return (this.form.vehicle?.processed?.missingItems || []).filter((part) => {
                return (part.pushedAt === null)
            })
        },

        returnedMissingParts() {
            return this.checkinMissingParts.filter((part) => {
                return (part.returnedAt !== null) || (part.returnedAt && !part.returnedAt?.split('T')[1])
            })
        },

        validReturnedMissingParts() {
            return this.checkinMissingParts.filter((part) => {
                return (part.returnedAt !== null)
            })
        },

        summarizedReturnedMissingParts() {
            return (this.form.vehicle?.processed?.missingItems || []).filter((item) => {
                return (this.listToReturn
                    .filter(list => (list.id == item.id)).length == 1) && item.returnedAt && !item.returnedAt?.split('T')[1]
            })
        }
    },

    methods: {
        validate() {
            let isValid = false

            if (
                (this.task == 'RecordMissingPartsCheckin') &&
                this.returnedMissingParts.length > 0
            ) {
                isValid = true // this.hasAllReturned
            }

            this.$emit('update:stepIsValid', isValid)
        },

        selectedItemName(item) {
            return missingHandoverItems?.filter(i => i.value == item)?.[0]?.text
        },

        setMissingPart(idx) {
            this.form.vehicle.processed.missingItems[idx].returnedAtLocation = true
        },

        displayDate(date) {
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }
            if (!date) {
                return (new Date()).toLocaleDateString('de-DE', options)
            }
            return (new Date(date)).toLocaleDateString('de-DE', options)
        },

        getToday() {
            return (new Date()).toISOString().split('T')[0]
        },

        toISODate(date) {
            return (new Date(date)).toISOString().split('T')[0]
        },

        returnAllItemsToday() {
            this.form.vehicle.processed.missingItems.map((item, idx) => {
                if (!this.form.vehicle.processed.missingItems[idx].returnedAt) {
                    this.form.vehicle.processed.missingItems[idx].returnedAt = this.getToday()
                    this.form.vehicle.processed.missingItems[idx].returnedAtLocation = true
                }
            })
        },

        clearAllItems() {
            this.form.vehicle.processed.missingItems.map((item, idx) => {
                if (
                    this.listToReturn
                        .filter(list => !list.pushedAt)
                        .filter(list => !list.returnedAt)
                        .filter(list => list.id == item.id).length == 1
                ) {
                    this.form.vehicle.processed.missingItems[idx].returnedAt = null
                    this.form.vehicle.processed.missingItems[idx].returnedAtLocation = false
                }
            })
        },
    }
}